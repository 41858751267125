import dayjs from "dayjs";
import { LogReefAPI } from "./logreef-api";
import { userConfig, UserConfigs } from "../common/user-config";

export function updateAuth() {
    const token = userConfig.get(UserConfigs.accessToken);
    const username = userConfig.get(UserConfigs.username);
    const expires_on = userConfig.get(UserConfigs.accessTokenExpireOn);

    // check if token is set
    if (!token || !username) {
        return false;
    }
    // check if expires data is valid

    if (dayjs().isAfter(dayjs(expires_on))) {
        // token not valid anymore
        logout();
        return false;
    }
    return true;
}

export async function login(
    api: LogReefAPI,
    username: string,
    password: string
) {
    let [status, dataUser] = await api.login(username, password);
    if (status !== 200) return [status, dataUser];

    // update user configs
    userConfig.setMany(
        [
            UserConfigs.accessToken,
            UserConfigs.accessTokenExpireOn,
            UserConfigs.username,
            UserConfigs.isDemo,
            UserConfigs.loggedInTime,
        ],
        [
            dataUser.access_token,
            dayjs(dataUser.expires_on).format(),
            username,
            dataUser.is_demo,
            dayjs().format(),
        ]
    );

    // refresh available aquariums for user
    let aquariumsData;
    [status, aquariumsData] = await api.getAquariums();
    if (status !== 200) return [status, dataUser];

    const aquariums = aquariumsData.map((aquarium) => aquarium.name);
    userConfig.setAquariums(aquariums);

    const selectedAquarium = userConfig.get(UserConfigs.selectedAquarium);
    if (!aquariums.includes(selectedAquarium)) {
        userConfig.set(UserConfigs.selectedAquarium, "");
    }
    if (aquariums.length === 1) {
        userConfig.set(UserConfigs.selectedAquarium, aquariums[0]);
    }
    return [status, dataUser];
}

export function logout() {
    userConfig.clear(
        UserConfigs.accessToken,
        UserConfigs.accessTokenExpireOn,
        UserConfigs.username,
        UserConfigs.isDemo
    );
}

export function getAccessToken(): string | null {
    return userConfig.get(UserConfigs.accessToken);
}

export function getUsername(): string | null {
    return userConfig.get(UserConfigs.username);
}

export function timeSinceLogin() {
    let loginTime = userConfig.get(UserConfigs.loggedInTime);
    if (!loginTime) return;
    const diffSecs = dayjs().diff(dayjs(loginTime), "seconds");
    return diffSecs;
}
